import React from "react";
import {
  Link
} from "react-router-dom";

import PremiumImage from '../../assets/img/authentication.svg';
import {useSelector} from 'react-redux';

export default function View(props) {

  const token = useSelector(state => state.auth.token);

  return (
    <div className="premium text-center">
        <img src={PremiumImage} height="300px"/>
        <h1>{props.customTitle ? props.customTitle : 'Oops! Recurso premium!' }</h1>
        <p>{props.customDesc ? props.customDesc : 'Você precisa ter um plano de assinatura para poder visualizar as resoluções em vídeo dos professores.' }</p>
        <Link to={'/assinatura'} className="site-button">ASSINAR PREMIUM</Link>{' '}
        {!token && <Link to={'/entrar'} className="site-button">FAZER LOGIN</Link>}
    </div>
  );
}