import React, {useEffect, useState} from "react";
import {
  Link,
  Redirect
} from "react-router-dom";

import Select from 'react-select'
import makeAnimated from 'react-select/animated';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import 'katex/dist/katex.min.css';

import { Form, FormGroup, Input, Label, Container, Row, Col, CustomInput } from 'reactstrap';
import { FiX } from 'react-icons/fi';

import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import Latex from 'react-latex';

import api from '../../../services/api';

import { WithContext as ReactTags } from 'react-tag-input';

const KeyCodes = {
  comma: 188,
  enter: 13,
};
const delimiters = [KeyCodes.comma, KeyCodes.enter];

export default function AddQuestion() {

  const [discipline, setDiscipline] = useState('');
  const [jury, setJury] = useState();
  const [institute, setInstitute] = useState('');
  const [year, setYear] = useState('');
  const [job, setJob] = useState();
  const [level, setLevel] = useState('Fundamental');
  const [formation, setFormation] = useState();
  const [act, setAct] = useState('');
  const [modal, setModal] = useState('Múltipla escolha');
  const [difficulty, setDifficulty] = useState('Muito fácil');

  const [wording, setWording] = useState('');

  const [alternatives, setAlternatives] = useState([{index: 0, correct: true, content: "", letter: 'a'}])

  const [redirect, redirectTo] = useState(false);

  const [saving, setSaving] = useState(false);

  const [tags, setTags] = useState([]);

  const [text, setText] = useState('');

  const [suggestions, setSuggestions] = useState([
    { id: "Thailand", text: "Thailand" },
    { id: "India", text: "India" }
  ]);

  const [videoLink, setVideoLink] = useState();
  const [comment, setComment] = useState('');

  const letters = ['a','b','c','d','e','f','g','h','i','j','k','l','m','n','o','p','q','r','s','t','u','v','w','x','y','z'];

  const [disciplines, setDisciplines] = useState([]);
  const [juries, setJuries] = useState([]);
  const [institutes, setInstitutes] = useState([]);
  const [acts, setActs] = useState([]);
  const [formations, setFormations] = useState([]);
  const [jobs, setJobs] = useState([]);

  useEffect(() => {
    //api.get('/tags').then(res => { setSuggestions(res.data.tags) });
    api.get('/disciplines').then(res => { setDisciplines(res.data.disciplines) });
    api.get('/juries').then(res => { setJuries(res.data.juries) });
    api.get('/institutes').then(res => { setInstitutes(res.data.institutes) });
    api.get('/acts').then(res => { setActs(res.data.acts) });
    api.get('/formations').then(res => { setFormations(res.data.formations) });
    api.get('/jobs').then(res => { setJobs(res.data.jobs) });
  }, []);

  function submit(e){
    setSaving(true);
    e.preventDefault();
    api.post('questions', {
      discipline_id: discipline,
      jury_id: jury,
      institute_id: institute,
      year,
      job_id: job,
      level,
      formation_id: formation,
      act_id: act,
      modal,
      difficulty,
      tags,
      wording,
      alternatives,
      videoLink,
      comment,
      text,
    }).then(res => {
      const { question } = res.data;

      redirectTo(true);
    })
  }

  function updateContent(data, index) {
    let arr = alternatives;
    arr[index].content = data;
    setAlternatives(arr);
  }

  function newAlternative(){
    setAlternatives([...alternatives, {index: alternatives.length, correct: false, content: "", letter: letters[alternatives.length]}]);
  }

  function removeAlternative(index){
    let arr = alternatives;
    arr = arr.filter(item => item.index != index);
    for(let i = 0; i < arr.length; i++){
      arr[i].letter = letters[i];
    }
    setAlternatives(arr);
  }

  function updateCorrect(value, index){
    let arr = alternatives;
    arr.map(alternative => {
      alternative.correct = false;
    })
    arr[index].correct = true;
    setAlternatives(arr);
    console.log(alternatives);
  }

  function handleDelete(i) {
    setTags(tags.filter((tag, index) => index !== i))
  }

  function handleAddition(tag) {
    setTags([...tags, tag])
  }

  function handleDrag(tag, currPos, newPos) {
    const tgs = [...tags];
    const newTags = tgs.slice();

    newTags.splice(currPos, 1);
    newTags.splice(newPos, 0, tag);

    // re-render
    setTags(newTags);

  }

  const [loading, setLoading] = useState('');

  return (
    !loading &&
    <>
      

      {
        redirect &&
        <Redirect to="/admin/questoes"/>
      }
      <div className="layout">
        <Container>
          <div className="adminHeader">
            <h1 className="title">Cadastro de questões</h1>
            <Link to="/admin/questoes" className="site-button site-button-sm">Questões</Link>
          </div>

          <div className="content">
            <Form onSubmit={submit}>
            <Row>
              <Col lg="3" className="mb-2">
                <FormGroup>
                  <Label for="">Disciplina</Label>
                  <Input type="select" name="select" id="exampleSelect" defaultValue={discipline} onChange={(e) => setDiscipline(e.target.value)} required>
                    <option value="">Escolha uma disciplina</option>
                    {
                      disciplines.map(discipline => (
                        <option value={discipline.id}>{discipline.name}</option>
                      ))
                    }
                  </Input>
                </FormGroup>
              </Col>
              <Col lg="3" className="mb-2">
                <FormGroup>
                  <Label for="">Banca</Label>
                  <Input type="select" name="select" defaultValue={jury} onChange={(e) => setJury(e.target.value)} required>
                    <option value="">Escolha uma banca</option>
                    {
                      juries.map(jury => (
                        <option value={jury.id}>{jury.name}</option>
                      ))
                    }
                  </Input>
                </FormGroup>
              </Col>
              <Col lg="2" className="mb-2">
                <FormGroup>
                  <Label for="">Cargo</Label>
                  <Input type="select" name="select" defaultValue={job} onChange={(e) => setJob(e.target.value)} required>
                    <option value="">Escolha um cargo</option>
                    {
                      jobs.map(job => (
                        <option value={job.id}>{job.name}</option>
                      ))
                    }
                  </Input>
                </FormGroup>
              </Col>
              <Col lg="2" className="mb-2">
                <FormGroup>
                  <Label for="">Instituição</Label>
                  <Input type="select" value={institute} onChange={(e) => setInstitute(e.target.value)} name="select" id="instituteSelect" required>
                    <option value="">Escolha uma instituição</option>
                    {
                      institutes.map(institute => (
                        <option value={institute.id}>{institute.name}</option>
                      ))
                    }
                  </Input>
                </FormGroup>
              </Col>
              <Col lg="2" className="mb-2">
                <FormGroup>
                  <Label for="">Ano</Label>
                  <Input type="select" name="select" id="exampleSelect" defaultValue={year} onChange={(e) => setYear(e.target.value)} required>
                    <option value="">Escolha um ano</option>
                    <option value="1985">1985</option>
                    <option value="1986">1986</option>
                    <option value="1987">1987</option>
                    <option value="1988">1988</option>
                    <option value="1989">1989</option>
                    <option value="1990">1990</option>
                    <option value="1991">1991</option>
                    <option value="1992">1992</option>
                    <option value="1993">1993</option>
                    <option value="1994">1994</option>
                    <option value="1995">1995</option>
                    <option value="1996">1996</option>
                    <option value="1997">1997</option>
                    <option value="1998">1998</option>
                    <option value="1999">1999</option>
                    <option value="2000">2000</option>
                    <option value="2001">2001</option>
                    <option value="2002">2002</option>
                    <option value="2003">2003</option>
                    <option value="2004">2004</option>
                    <option value="2005">2005</option>
                    <option value="2006">2006</option>
                    <option value="2007">2007</option>
                    <option value="2008">2008</option>
                    <option value="2009">2009</option>
                    <option value="2010">2010</option>
                    <option value="2011">2011</option>
                    <option value="2012">2012</option>
                    <option value="2013">2013</option>
                    <option value="2014">2014</option>
                    <option value="2015">2015</option>
                    <option value="2016">2016</option>
                    <option value="2017">2017</option>
                    <option value="2018">2018</option>
                    <option value="2019">2019</option>
                    <option value="2020">2020</option>
                  </Input>
                </FormGroup>
              </Col>
              
              <Col lg="3" className="mb-2">
                <FormGroup>
                  <Label for="">Área de formação</Label>
                  <Input type="select" name="select" defaultValue={formation} onChange={(e) => setFormation(e.target.value)} required>
                    <option value="">Escolha uma área</option>
                    {
                      formations.map(formation => (
                        <option value={formation.id}>{formation.name}</option>
                      ))
                    }
                  </Input>
                </FormGroup>
              </Col>
              <Col lg="3" className="mb-2">
                <FormGroup>
                  <Label for="">Área de atuação</Label>
                  <Input type="select" name="select" defaultValue={act} id="exampleSelect" onChange={(e) => setAct(e.target.value)}>
                    <option value="">Escolha uma área</option>
                    {
                      acts.map(act => (
                        <option value={act.id}>{act.name}</option>
                      ))
                    }
                  </Input>
                </FormGroup>
              </Col>
              <Col lg="2" className="mb-2">
                <FormGroup>
                  <Label for="">Modalidade</Label>
                  <Input type="select" name="select" defaultValue={modal} id="exampleSelect" onChange={(e) => setModal(e.target.value)}>
                    <option value="Múltipla escolha">Múltipla escolha</option>
                    <option value="Certo ou errado">Certo ou errado</option>
                    <option value="Aberta">Aberta</option>
                  </Input>
                </FormGroup>
              </Col>
              <Col lg="2" className="mb-2">
                <FormGroup>
                  <Label for="">Dificuldade</Label>
                  <Input type="select" name="select" id="exampleSelect" onChange={(e) => setDifficulty(e.target.value)}>
                    <option value="Muito fácil">Muito fácil</option>
                    <option value="Fácil">Fácil</option>
                    <option value="Médio">Médio</option>
                    <option value="Difícil">Difícil</option>
                    <option value="Muito difícil">Muito difícil</option>
                  </Input>
                </FormGroup>
              </Col>
              <Col lg="2" className="mb-2">
                <FormGroup>
                  <Label for="">Nível</Label>
                  <Input type="select" name="select" id="exampleSelect" onChange={(e) => setLevel(e.target.value)}>
                    <option value="Fundamental">Fundamental</option>
                    <option value="Médio">Médio</option>
                    <option value="Superior">Superior</option>
                  </Input>
                </FormGroup>
              </Col>
            </Row>

            <FormGroup>
              <Label for="">Assuntos</Label>
              
              <div>
                <ReactTags tags={tags}
                  suggestions={suggestions}
                  delimiters={delimiters} 
                  handleDelete={handleDelete}
                  handleAddition={handleAddition}
                  handleDrag={handleDrag}
                  placeholder="Adicione os assuntos separados por virgula ou enter"/>
                </div>
            </FormGroup>

            <Label className="mt-4">Texto associado</Label>
            {
              /*
            <CKEditor
              className="d-inline"
              editor={ ClassicEditor }
              data={text}
              onChange={ ( event, editor ) => {
                  const data = editor.getData();
                  setText(data);
              } }
              config={{
                ckfinder: {uploadUrl: "https://api.persistir.codenative.com.br/api/ckeditor/upload"},
                removePlugins: [ 'ImageCaption', 'ImageToolbar', 'MathType', 'ChemType' ],
                toolbar: [
                  'bold',
                  'underline', 
                  'italic',
                  'strikethrough', 
                  'fontColor', 
                  'fontBackgroundColor',
                  '|',
                  'imageUpload',
                  'blockQuote',
                  'undo',
                  'redo',
                ],
              }}
            />
              */
            }

            <ReactQuill 
              theme="snow" 
              value={text} 
              onChange={setText}
              modules={{
                toolbar: [
                  [{ 'header': [1, 2, false] }],
                  ['bold', 'italic', 'underline','strike', 'blockquote'],
                  [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
                  ['link', 'image'],
                  ['formula', 'script']
                ],
              }}
            />

            <Label className="mt-4">Enunciado</Label>
            {
              /*
              <CKEditor
              className="d-inline"
              editor={ ClassicEditor }
              data={wording}
              onChange={ ( event, editor ) => {
                  const data = editor.getData();
                  setWording(data);
              } }
              config={{
                ckfinder: {uploadUrl: "https://api.persistir.codenative.com.br/api/ckeditor/upload"},
                removePlugins: [ 'ImageCaption', 'ImageToolbar', 'MathType', 'ChemType' ],
                toolbar: [
                  'bold',
                  'underline', 
                  'italic',
                  'strikethrough', 
                  'fontColor', 
                  'fontBackgroundColor',
                  '|',
                  'mathpreview',
                  'subscript', 
                  'superscript',
                  '|',
                  'link',
                  'bulletedList',
                  'numberedList',
                  '|',
                  'imageUpload',
                  'blockQuote',
                  'insertTable',
                  'undo',
                  'redo',
                  'MathType',
                  'ChemType'
                ],
              }}
            />
              */
            }
            <ReactQuill 
              theme="snow" 
              value={wording} 
              onChange={setWording}
              modules={{
                toolbar: [
                  [{ 'header': [1, 2, false] }],
                  ['bold', 'italic', 'underline','strike', 'blockquote'],
                  [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
                  ['link', 'image'],
                  ['formula', 'script']
                ],
              }}
            />

            <div className="mt-4">
              <Label className="mb-3">Alternativas</Label> <button type="button" className="site-button site-button-sm" onClick={newAlternative}>Nova alternativa</button>

              {
                alternatives.map((alternative, index) => (
                  <FormGroup key={index}>
                    <Row>
                      <Col md="1" className="d-flex align-items-center">
                        <h2 className="mr-2">{letters[index]})</h2>
                        <CustomInput type="radio" id={`radio${index}`} onChange={(e) => updateCorrect(e.target.value, index)} name="alternatives" defaultChecked={alternative.correct}/>
                        <button style={{fontSize: '20px', marginBottom: '5px', background: 'none', border: 'none'}}><FiX onClick={(e) => removeAlternative(index)} style={{cursor: 'pointer'}}/></button>
                      </Col>
                      <Col md="11">
                        {
                          /*
                          <CKEditor
                            className="d-inline"
                            editor={ ClassicEditor }
                            data={alternative.content}
                            onChange={ ( event, editor ) => {
                                const data = editor.getData();
                                updateContent(data, index);
                            } }
                            config={{
                              ckfinder: {uploadUrl: "https://api.persistir.codenative.com.br/api/ckeditor/upload"},
                              removePlugins: [ 'ImageCaption', 'ImageToolbar', 'MathType', 'ChemType' ],
                              toolbar: [
                                'bold',
                                'underline', 
                                'italic',
                                'strikethrough', 
                                'fontColor', 
                                'fontBackgroundColor',
                                '|',
                                'mathpreview',
                                'subscript', 
                                'superscript',
                                '|',
                                'link',
                                'bulletedList',
                                'numberedList',
                                '|',
                                'imageUpload',
                                'blockQuote',
                                'insertTable',
                                'undo',
                                'redo',
                                'MathType',
                                'ChemType'
                              ],
                            }}
                          />
                          */
                        }
                        <ReactQuill 
                          theme="snow" 
                          value={alternative.content} 
                          onChange={(data) => updateContent(data, index)}
                          modules={{
                            toolbar: [
                              [{ 'header': [1, 2, false] }],
                              ['bold', 'italic', 'underline','strike', 'blockquote'],
                              [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
                              ['link', 'image'],
                              ['formula', 'script']
                            ],
                          }}
                        />
                      </Col>
                    </Row>
                  </FormGroup>
                ))
              }
            </div>

              <FormGroup>
                <Label for="videoLink">Link do vídeo da resolução</Label>
                <Input type="text" id="videoLink" value={videoLink} onChange={(e) => setVideoLink(e.target.value)}/>
              </FormGroup>

              <FormGroup>
                <Label for="comments">Comentários do professor</Label>
                {
                  /*
                <CKEditor
                  className="d-inline"
                  editor={ ClassicEditor }
                  data={comment}
                  onChange={ ( event, editor ) => {
                      const data = editor.getData();
                      setComment(data);
                  } }
                  config={{
                    ckfinder: {uploadUrl: "https://api.persistir.codenative.com.br/api/ckeditor/upload"},
                    removePlugins: [ 'ImageCaption', 'ImageToolbar', 'MathType', 'ChemType' ],
                    toolbar: [
                      'bold',
                      'underline', 
                      'italic',
                      'strikethrough', 
                      'fontColor', 
                      'fontBackgroundColor',
                      '|',
                      'mathpreview',
                      'subscript', 
                      'superscript',
                      '|',
                      'link',
                      'bulletedList',
                      'numberedList',
                      '|',
                      'imageUpload',
                      'blockQuote',
                      'insertTable',
                      'undo',
                      'redo',
                      'MathType',
                      'ChemType'
                    ],
                  }}
                />
                  */
                }
                <ReactQuill 
                  theme="snow" 
                  value={comment} 
                  onChange={setComment}
                  modules={{
                    toolbar: [
                      [{ 'header': [1, 2, false] }],
                      ['bold', 'italic', 'underline','strike', 'blockquote'],
                      [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
                      ['link', 'image'],
                      ['formula', 'script']
                    ],
                  }}
                />
              </FormGroup>

              <div className="text-right">
                <button className="site-button" type="submit" disabled={saving}>{saving ? 'Cadastrando...' : 'Cadastrar questão'}</button>
              </div>
            </Form>
          </div>
        </Container>
      </div>
    </>
  );
}