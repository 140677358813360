import React, { useEffect, useState } from "react";
import {
  Link,
  Redirect
} from "react-router-dom";

import { Form, FormGroup, Input, Label, Row, Col, Container, CustomInput, Table } from 'reactstrap';

import AdminLayout from '../../../layouts/Admin';
import api from '../../../services/api';
import { ResponsiveContainer, PieChart, Pie, Sector, Cell, AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip } from 'recharts';

export default function View() {

    const [users, setUsers] = useState([]);
    const [totalSubscribers, setTotalSubscribers] = useState(0); 
    const [totalAmount, setTotalAmount] = useState(0); 
    const [dataArea, setDataArea] = useState([]);

    useEffect(() => {
        api.get(`subscribers/dashboard`).then(res => {
            setTotalSubscribers(res.data.totalSubscribers);
            setTotalAmount(res.data.totalAmount);
            var datas = [];
            for (let [key, value] of Object.entries(res.data.flow)) {
              datas.push({
                name: key,
                Total: value.length,
              });
            }
            setDataArea(datas);
        });

        api.get(`subscribers`).then(res => {
            setUsers(res.data.subscribers);
        });
    }, []);

    return (
        <AdminLayout
            title="Assinantes"
        >
            <Row className="mb-4">
                <Col lg={3} className="d-flex justify-content-between d-lg-block">
                    <div className="text-left">
                        Assinantes ativos
                        <h1>{totalSubscribers}</h1>
                    </div>
                    <div className="text-left">
                        Faturamento do mês
                        <h1>R${totalAmount.toLocaleString('pt-br', {minimumFractionDigits: 2})}</h1>
                    </div>
                </Col>
                <Col lg={9}>
                    <div className="text-center">
                        Fluxo de assinantes
                    </div>
                    <div style={{ width: '100%', height: 200 }}>
                        <ResponsiveContainer>
                            <AreaChart
                                width={500}
                                height={400}
                                data={dataArea}
                                margin={{
                                top: 10, right: 30, left: 0, bottom: 0,
                                }}
                            >
                                <XAxis dataKey="name" />
                                <YAxis />
                                <Tooltip />
                                <Area type="monotone" dataKey="Total" stroke="#3793F5" fill="#50A4FD" />
                            </AreaChart>
                        </ResponsiveContainer>
                    </div>
                </Col>
            </Row>

            <Table responsive>
                <thead>
                    <tr>
                        <th>Nome</th>
                        <th>Email</th>
                        <th>Plano</th>
                        <th>Assinado em</th>
                        <th>Até</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        users.map(user => (
                            <tr>
                                <td>{user.name}</td>
                                <td>{user.email}</td>
                                <td>{user.plan}</td>
                                <td>{user.signDate}</td>
                                <td>{user.signLimit}</td>
                            </tr>
                        ))
                    }
                </tbody>
            </Table>
        </AdminLayout>
    );
}