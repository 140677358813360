import React, { useEffect, useState } from "react";
import {
  Link,
  Redirect,
  useLocation
} from "react-router-dom";

import { Row, Col, Container  } from 'reactstrap';
import CourseCard from '../../components/Courses/Card';

import Layout from '../Site';
import api from '../../services/api';

export default function CourseLayout(props) {

    const location = useLocation();

    useEffect(() => {
    }, []);

    return (
    <Layout>
        <Container>
            <Row className="profile">
                <Col lg="3">
                    <div className="profile-card">
                        <div className="wrapper">
                            <CourseCard 
                                color="#14763a" 
                                image={api.defaults.baseURL.replace('.com.br/api', '.com.br/') + props.thumbPath} 
                            />
                            <h1 className="name">{props.name}</h1>
                        </div>
                        <ul className="sidebarul">
                            <Link to={`#`}><li className="selected">Aulas</li></Link>
                            <Link to="/perfil/meus-cursos"><li className={location.pathname == '/perfil/minhas-questoes' ? 'selected' : ''}>Meus cursos</li></Link>
                        </ul>
                    </div>
                </Col>
                <Col lg="9">
                    {props.children}
                </Col>
            </Row>
        </Container>
    </Layout>
  );
}