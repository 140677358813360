import React, { useEffect, useState } from "react";
import api from '../../services/api';

export default function Component(props) {
	const [problems, setProblems] = props.state;
	const [user, setUser] = useState(undefined);

	useEffect(() => {
		api.get(`users/${props.problem.reported_by}`).then(res => {setUser(res.data.user)});
	}, []);

	const handleSolvedProblem = () => {
		if(props.problem.solved === 0) {
			api.put(`problems/${props.problem.id}`, { solved: 1 }).then(res => {
				props.problem.solved = 1;
				if(res.status === 200) setProblems([...problems.filter(problem => problem.id !== props.problem.id), props.problem]);
			});
		}
	}

	return (
		<div className="problem">
			<div className="questionId">
				{props.problem.id}
				<div className="by">Relatado por: {user ? user.name : ''}</div>
			</div>
			<div className="description">
				{props.problem.description}
			</div>
			<div className="actions">
				{props.problem.solved === 0 ? <>
					<button onClick={handleSolvedProblem} className="site-button site-button-sm site-button-dark">Marcar como resolvido</button>{' '}
				</> : ''}
				<a href={`questoes/${props.problem.question_id}`} target="_blank" className="site-button site-button-sm">Ver questão</a>
			</div>
		</div>
	);
}