import React, { useEffect, useState } from "react";
import { Container, Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import Layout from '../../../layouts/Site';
import Question from '../../../components/Questions/Question';
import Filters from '../../../components/Questions/Filters';
import api from '../../../services/api';

export default function Home(props) {
	const [questions, setQuestions] = useState([]);

	useEffect(() => {
		switch(props.match.params.questions){
			case 'resolvidas':
				return api.get('my/questions/solved').then(res => {setQuestions(res.data.questions)});
			case 'comentadas':
				return api.get('my/questions/commented').then(res => {setQuestions(res.data.questions)});
			case 'anotadas':
				return api.get('my/questions/annoted').then(res => {setQuestions(res.data.questions)});
		}
	}, []);

	return (
		<Layout>
			<section id="questoes">
				<Container className="py-3">
					<h1 style={{fontSize: '1.5em'}}>Questões de concurso</h1>
				</Container>
				<Filters filterFunction={setQuestions} />
			</section>
			<Container>
				<section id="questions" className="section">
					{ questions.map(question => <Question key={question.id} question={question} />) }
				</section>
			</Container>
		</Layout>
	);
}