import React, { useEffect, useState } from "react";
import {
  Link,
  Redirect
} from "react-router-dom";
import { Form, FormGroup, Input, Label, Row, Col, Container, CustomInput, Table } from 'reactstrap';
import ConfirmationDialog from '../../../../../components/ConfirmationDialog';
import AdminLayout from '../../../../../layouts/Admin';
import api from '../../../../../services/api';
import { useForm } from "react-hook-form";

export default function View(props) {

    const { register, handleSubmit } = useForm();
    const [courseClass, setCourseClass] = useState({});
    const [files, setFiles] = useState([]);
    const [toClasses, setToClasses] = useState(false);
    const [newFiles, setNewFiles] = useState([]);
    const [inputFileName, setInputFileName] = useState("");
    const [inputFile, setInputFile] = useState("");
    const [invalidName, setInvalidName] = useState(false);
    const [invalidFile, setInvalidFile] = useState(false);
    const [filesToDelete, setFilesToDelete] = useState([]);

    useEffect(() => {
        api.get(`classes/${props.match.params.aula}`).then(res => {
            setCourseClass(res.data.courseClass);
            setFiles(res.data.files);
        })
    }, []);

    const [confirmationDialog, setConfirmationDialog] = useState(false);

    function toggleDialog(){
        setConfirmationDialog(!confirmationDialog);
    }

    function callbackDialog(){
        setFilesToDelete([...filesToDelete, confirmationDialog]);
        setFiles(files.filter(file => file.id != confirmationDialog));
        toggleDialog();
    }

    function submit(data){
        let formData = new FormData();
        formData.append('title', data.title);
        formData.append('videoLink', data.videoLink);
        if(data.thumb[0])
            formData.append('thumb', data.thumb[0]);
        formData.append('_method', 'PUT');
        newFiles.map(file => {
            formData.append('archives[]', file.file);
            formData.append('filenames[]', file.name);
        });

        api.post(`classes/${props.match.params.aula}`, formData ).then(res => {
            setToClasses(true);
        });

        filesToDelete.map(file => {
            api.delete(`classes/file/${file}`);
        })
    }

    function addFile(){
        if(inputFileName == ""){
            return setInvalidName(true);
        }else if(!inputFile){
            return setInvalidFile(true);
        }

        let file = {
            id: files.length + 1,
            name: inputFileName,
            file: inputFile
        };
        setFiles([...files, file]);
        setNewFiles([...newFiles, file]);
        setInputFileName("");
        setInputFile(null)
        setInvalidFile(false);
        setInvalidName(false);
        document.getElementById('file').value = "";
    }

    return (
        <Form onSubmit={handleSubmit(submit)}>
            {toClasses && <Redirect to={`/admin/cursos/${props.match.params.id}/aulas`}/>}
            <AdminLayout
                title={courseClass.title}
                extra={
                    <button type="submit" className="site-button site-button-sm">Salvar alterações</button>
                }
            >
                <FormGroup>
                    <Label for="title">Título da aula</Label>
                    <Input type="text" id="title" name="title" defaultValue={courseClass.title} innerRef={register({ required: true })} required/>
                </FormGroup>
                <FormGroup>
                    <Label for="link">Link do vídeo</Label>
                    <Input type="text" id="link" name="videoLink" defaultValue={courseClass.videoLink} innerRef={register({ required: true })} required/>
                </FormGroup>
                <div className="d-flex my-4">
                    <img src={api.defaults.baseURL.replace('.com.br/api', '.com.br/') + courseClass.thumbPath} height="80px"/>
                    <FormGroup>
                        <Label for="thumb">Thumbnail selecione para trocar</Label>
                        <Input type="file" id="thumb" name="thumb" innerRef={register()}/>
                    </FormGroup>
                </div>

                <strong>Arquivos</strong>
                <Row className="align-items-center">
                    <Col lg="5">
                        <FormGroup>
                            <Label for="fileName">Nome do arquivo</Label>
                            <Input type="text" id="fileName" value={inputFileName} onChange={(e) => setInputFileName(e.target.value)} invalid={invalidName}/>
                        </FormGroup>
                    </Col>
                    <Col lg="5">
                        <FormGroup>
                            <Label for="file">Arquivo</Label>
                            <Input type="file" id="file" onChange={() => setInputFile(document.getElementById('file').files[0])} invalid={invalidFile}/>
                        </FormGroup>
                    </Col>
                    <Col lg="2">
                        <FormGroup>
                            <button type="button" id="addbtn" className="site-button site-button-sm mt-2" onClick={addFile}>Adicionar</button>
                        </FormGroup>
                    </Col>
                </Row>
                <Table responsive>
                    <tbody>
                        {
                            files.map((file, index) => (
                                <tr key={'file'+index}>
                                    <td className="text-center">{index + 1}</td>
                                    <td>{file.name}</td>
                                    <td className="text-right">
                                        <button type="button" className="site-button site-button-danger site-button-sm" onClick={() => setConfirmationDialog(file.id)}>Remover</button>
                                    </td>
                                </tr>
                            ))
                        }
                    </tbody>
                </Table>
            </AdminLayout>

            
            <ConfirmationDialog
                isOpen={confirmationDialog}
                toggle={toggleDialog}
                callback={callbackDialog}
            >
                Você tem certeza que deseja remover esse arquivo?
            </ConfirmationDialog>
        </Form>
    );
}