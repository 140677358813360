import React, { useEffect, useState } from "react";
import {
  Link,
  Redirect
} from "react-router-dom";

import { Form, FormGroup, Input, Label, Row, Col, Container, FormFeedback  } from 'reactstrap';
import ProfileLayout from '../../../../layouts/Profile';
import Wrapper from '../../../..//components/Profile/Wrapper';
import api from '../../../../services/api';
import cepPromise from 'cep-promise'
import InputMask from 'react-input-mask';

export default function View() {

  const [user, setUser] = useState({});
  const [saving, setSaving] = useState(false);

  const [name, setName] = useState();
  const [birthday, setBirthday] = useState();
  const [email, setEmail] = useState();
  const [cel, setCel] = useState();
  const [cep, setCep] = useState();
  const [state, setState] = useState();
  const [city, setCity] = useState();
  const [neighborhood, setNeighborhood] = useState();
  const [street, setStreet] = useState();
  const [number, setNumber] = useState();
  const [complement, setComplement] = useState();

  const [password, setPassword] = useState();
  const [newPassword, setNewPassword] = useState();

  const [invalidEmail, setInvalidEmail] = useState(false);

  const [diffPassword, setDiffPassword] = useState();

  useEffect(() => {
    api.get('auth/user').then(res => {
      const { user } = res.data;
      setName(user.name);
      setEmail(user.email);
      setCel(user.cel);
      setCep(user.cep);
      setBirthday(user.birthday);
      setState(user.state);
      setCity(user.city);
      setNeighborhood(user.neighborhood);
      setStreet(user.street);
      setNumber(user.number);
      setComplement(user.complement);
    });
  }, []);

  function submit(e){
    e.preventDefault();
    setSaving(true)
    api.put('users', {
      name,
      email,
      cel,
      cep,
      birthday,
      state,
      city,
      neighborhood,
      street,
      number,
      complement
    }).then(res => {
      setSaving(false);
    }).catch(err => {
      if(err.response.data.message == 'Invalid email'){
        setInvalidEmail(true);

        setTimeout(function(){ setInvalidEmail(false) }, 3000);
      }
    })
  }

  function changeCep(value){
    setCep(value);
    if(value.length == 9){
      cepPromise(value).then( data => {
        setState(data.state);
        setCity(data.city);
        setNeighborhood(data.neighborhood);
        setStreet(data.street);
      });
    }
  }

  return (
    <ProfileLayout
      name={user.name}
    >
      <Form onSubmit={submit}>
        <Wrapper title="Meus dados"
          extra={
            <button type="submit" className="site-button site-button-sm" disabled={saving}>{saving ? 'Salvando...' : 'Salvar alterações'}</button>
          }
        >
          <Container fluid>
            <Row>
              <Col xs="12" className="p-0"><h1 className="formTitle">Dados pessoais</h1></Col>
              <Col lg="6" className="pl-lg-0 pr-lg-1">
                <FormGroup>
                  <Label for="">Nome</Label>
                  <Input type="text" value={name} onChange={(e) => setName(e.target.value)}/>
                </FormGroup>
              </Col>
              <Col lg="6" className="pl-lg-0 pl-lg-1">
                <FormGroup>
                  <Label for="">Nascimento</Label>
                  <Input type="date" value={birthday} onChange={(e) => setBirthday(e.target.value)}/>
                </FormGroup>
              </Col>
              
              <Col lg="6" className="pl-lg-0 pr-lg-1">
                <FormGroup>
                  <Label for="">Celular</Label>
                  <Input type="text" value={cel} onChange={(e) => setCel(e.target.value)} mask="(99) 9 9999-9999" tag={InputMask}/>
                </FormGroup>
              </Col>
              <Col lg="6" className="pl-lg-0 pl-lg-1">
                <FormGroup>
                  <Label for="">E-mail</Label>
                  <Input type="text" value={email} onChange={(e) => setEmail(e.target.value)} invalid={invalidEmail}/>
                  <FormFeedback>Este e-mail já está cadastrado em nosso site.</FormFeedback>
                </FormGroup>
              </Col>
              
              <Col xs="12" className="p-0 mt-3"><h1 className="formTitle">Endereço</h1></Col>
              <Col lg="3" className="pl-lg-0 pr-lg-1">
                <FormGroup>
                  <Label for="">CEP</Label>
                  <Input type="text" value={cep} onChange={(e) => changeCep(e.target.value)} mask="99999-999" tag={InputMask}/>
                </FormGroup>
              </Col>
              <Col lg="2" className="pl-lg-0 pl-lg-1">
                <FormGroup>
                  <Label for="">Estado</Label>
                  <Input type="text" value={state} onChange={(e) => setState(e.target.value)}/>
                </FormGroup>
              </Col>
              
              <Col lg="3" className="pl-lg-0 pr-lg-1">
                <FormGroup>
                  <Label for="">Cidade</Label>
                  <Input type="text" value={city} onChange={(e) => setCity(e.target.value)}/>
                </FormGroup>
              </Col>
              <Col lg="4" className="pl-lg-0 pl-lg-1">
                <FormGroup>
                  <Label for="">Bairro</Label>
                  <Input type="text" value={neighborhood} onChange={(e) => setNeighborhood(e.target.value)}/>
                </FormGroup>
              </Col>
              
              <Col lg="6" className="pl-lg-0 pr-lg-1">
                <FormGroup>
                  <Label for="">Rua</Label>
                  <Input type="text" value={street} onChange={(e) => setStreet(e.target.value)}/>
                </FormGroup>
              </Col>
              <Col lg="3" className="pl-lg-0 pl-lg-1">
                <FormGroup>
                  <Label for="">Número</Label>
                  <Input type="text" value={number} onChange={(e) => setNumber(e.target.value)}/>
                </FormGroup>
              </Col>
              <Col lg="3" className="pl-lg-0 pl-lg-1">
                <FormGroup>
                  <Label for="compl">Compl.</Label>
                  <Input type="text" id="compl" value={complement} onChange={(e) => setComplement(e.target.value)}/>
                </FormGroup>
              </Col>
              
              {
                false &&
                <>
                <Col xs="12" className="p-0 mt-3"><h1 className="formTitle">Trocar senha</h1></Col>
                <Col lg="6" className="pl-lg-0 pr-lg-1">
                  <FormGroup>
                      <Label for="newpass">Senha</Label>
                      <Input type="password" id="newpass" autoComplete="off" value={password} onChange={(e) => setPassword(e.target.value)} invalid={diffPassword} required/>
                  </FormGroup>
                </Col>
                <Col lg="6" className="pl-lg-0 pl-lg-1">
                  <FormGroup>
                      <Label for="oldpass">Confirme sua senha</Label>
                      <Input type="password" id="oldpass" autoComplete="off" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} invalid={diffPassword} required/>
                      <FormFeedback>As senhas não são iguais!</FormFeedback>
                  </FormGroup>
                </Col>
                </>
              }
            </Row>
          </Container>
        </Wrapper>
      </Form>
    </ProfileLayout>
  );
}