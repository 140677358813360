import React, { useEffect, useState } from "react";
import { Input  } from 'reactstrap';
import AdminLayout from '../../../layouts/Admin';
import Problem from '../../../components/Problem';
import api from '../../../services/api';

export default function View() {
	const [tab, setTab] = useState(0);
	const [problems, setProblems] = useState([]);

	useEffect(() => {
		api.get('problems').then(res => {setProblems(res.data.problems.data)});
	}, []);

	return (
		<AdminLayout
			title="Problemas relatados"
			extra={
				<Input type="select" name="select" onChange={(e) => setTab(e.target.value)} size="sm">
					<option value={0}>Não resolvidos</option>
					<option value={1}>Resolvidos</option>
				</Input>
			}
		>
			{ problems.filter(problem => problem.solved == tab).map(problem => <Problem key={problem.id} problem={problem} state={[problems, setProblems]} />) }
		</AdminLayout>
	);
}