import React, { useEffect, useState } from "react";
import {
  Link,
  Redirect
} from "react-router-dom";


export default function Thumb(props) {

  useEffect(() => {
  }, []);

  return (
        props.classId ?
            <Link to={`/perfil/cursos/${props.courseId}/${props.classId}`} className={"classThumb " + (props.watched ? "classWatched" : "")}>
                <img src={props.image} className="img-fluid" alt={props.name}/>
            </Link>
        :
            <div className={"classThumb " + (props.watched ? "classWatched" : "")}>
                <img src={props.image} className="img-fluid" alt={props.name}/>
            </div>
  );
}