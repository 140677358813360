import React, { useEffect, useState } from "react";
import {
  Link,
  Redirect
} from "react-router-dom";

import { Form, FormGroup, Input, Label, Row, Col, Container, CustomInput  } from 'reactstrap';

import CodenativeLogo from '../../assets/img/codenative-logo.png';

import { useSelector } from 'react-redux';

export default function Footer() {

  const token = useSelector(state => state.auth.token);

  useEffect(() => {
  }, []);

  return (
    <>
      <footer>
        <Container>
          <div className="footer pb-5 pb-lg-0 mb-5 mb-lg-0">
            <Row>
              <Col lg={6}>
                <ul>
                  <li>
                    <Link to="/">Início</Link>
                  </li>
                  <li>
                    <Link to="/questoes">Questões</Link>
                  </li>
                  <li>
                    <Link to="/cursos-online">Curso online</Link>
                  </li>
                  <li>
                    <Link to="/presencial">Presencial</Link>
                  </li>
                  <li>
                    <Link to="/assinatura">Assinatura</Link>
                  </li>
                  {
                    token ?
                    <li>
                      <Link to="/perfil">Meu perfil</Link>
                    </li>
                    :
                    <li>
                      <Link to="/cadastre-se">Cadastre-se</Link>
                    </li>
                  }
                </ul>
              </Col>
              <Col lg={6} className="text-lg-right">
                  <ul>
                    <li><i class="fab fa-whatsapp"></i> (32) 9 9185-1977 (WhatsApp)</li>
                    <li><i class="fas fa-map-marker-alt"></i> Av. Astolfo Dutra, 792 - 1º andar - Centro, Cataguases - MG, 36770-001</li>
                  </ul>
              </Col>
            </Row>

            <a href="https://codenative.com.br" target="_blank">
              <img src={CodenativeLogo} height="70px" className="logo"/>
            </a>
          </div>
        </Container>
      </footer>
    </>
  );
}