import React, { useEffect, useState } from "react";
import {
  Link,
  Redirect
} from "react-router-dom";

import { Form, FormGroup, Input, Label, Row, Col, Container, CustomInput  } from 'reactstrap';

import ProfileLayout from '../../../../layouts/Profile';

import Wrapper from '../../../../components/Profile/Wrapper';
import { Card } from '../../../../components/Cards';

import api from '../../../../services/api';

export default function View() {

  const [qtds, setQtds] = useState({});

  useEffect(() => {
    api.get(`my/questions`).then(res => {
      setQtds({
        'commented': res.data.commented,
        'annoted': res.data.annoted,
        'solved': res.data.solved.length,
      });
    });
  }, []);

  return (
    <ProfileLayout>
        <Wrapper title="Minhas questões">
          <Row>
            <Col lg={4}>
              <Link to="/questoes/resolvidas">
                <Card title="Questões resolvidas" big={qtds.solved}>
                  
                  <div className="mt-3">
                    <Link to="/questoes/resolvidas">Ver questões</Link>
                  </div>
                </Card>
              </Link>
            </Col>
            <Col lg={4}>
              <Link to="/questoes/comentadas">
                <Card title="Questões comentadas" big={qtds.commented}>
                  
                  <div className="mt-3">
                    <Link to="/questoes/comentadas">Ver questões</Link>
                  </div>
                </Card>
              </Link>
            </Col>
            <Col lg={4}>
              <Link to="/questoes/anotadas">
                <Card title="Questões anotadas" big={qtds.annoted}>
                  
                  <div className="mt-3">
                    <Link to="/questoes/anotadas">Ver questões</Link>
                  </div>
                </Card>
              </Link>
            </Col>
          </Row>
        </Wrapper>
    </ProfileLayout>
  );
}