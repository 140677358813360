import React, { useEffect, useState } from "react";
import {
  Link,
  Redirect
} from "react-router-dom";

import { 
    Container, 
    Collapse,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    NavItem,
    NavLink,
    NavbarText  
} from 'reactstrap';

import Logo from '../../assets/img/logo.svg';

import { useSelector } from 'react-redux';
import jwtDecode from 'jwt-decode';

export default function NavbarComponent() {

    const token = useSelector(state => state.auth.token);
    const [user, setUser] = useState({});
    const [isOpen, setIsOpen] = useState(false);
  
    const toggle = () => setIsOpen(!isOpen);

    useEffect(() => {
        if(token)
            setUser(jwtDecode(token).user);
    }, []);

    return (
        <>
            <Container>
            <div>
                <Navbar color="light" light expand="md">
                    <NavbarBrand href="/">
                        <div className="logo">
                            <img src={Logo} height="70px"/>
                        </div>
                    </NavbarBrand>
                    <NavbarToggler onClick={toggle} />
                    <Collapse isOpen={isOpen} navbar>
                        <Nav className="ml-auto navlinks" navbar>
                            <NavItem>
                                <Link to="/">Início</Link>
                            </NavItem>
                            <NavItem>
                                <Link to="/questoes">Questões</Link>
                            </NavItem>
                            <NavItem>
                                <Link to="/cursos-online">Cursos online</Link>
                            </NavItem>
                            <NavItem>
                                <Link to="/presencial">Presencial</Link>
                            </NavItem>
                            <NavItem>
                                <Link to="/assinatura">Assinatura</Link>
                            </NavItem>
                            {
                                user.type == 'admin' &&
                                <NavItem>
                                    <Link to="/perfil"><i className="fas fa-user-circle Centered" style={{fontSize: '1.3em', marginRight: '5px', transform: 'translateY(2px)'}}></i> Meu perfil</Link>
                                </NavItem>
                            }
                            {
                                user.type == 'admin' ?
                                    <NavItem>
                                        <Link to="/admin" className="site-button"><span className="Centered">Administração</span></Link>
                                    </NavItem>
                                :
                                (
                                    token ?
                                    <NavItem>
                                        <Link to="/perfil" className="site-button"><i className="fas fa-user-circle Centered" style={{fontSize: '1.3em', marginRight: '5px'}}></i> <span className="Centered">Meu perfil</span></Link>
                                    </NavItem>
                                    :
                                    <NavItem>
                                        <Link to="/cadastre-se" className="site-button">Cadastre-se grátis</Link>
                                    </NavItem>
                                )
                            }
                        </Nav>
                    </Collapse>
                </Navbar>
            </div>
            {
                false &&
                <nav>
                    <div className="navbar">
                        <Link to="/">
                            <div className="logo">
                                <img src={Logo} height="70px"/>
                            </div>
                        </Link>
                        <ul className="navlinks">
                            <li>
                                <Link to="/">Início</Link>
                            </li>
                            <li>
                                <Link to="/questoes">Questões</Link>
                            </li>
                            <li>
                                <Link to="/cursos-online">Cursos online</Link>
                            </li>
                            <li>
                                <Link to="/presencial">Presencial</Link>
                            </li>
                            <li>
                                <Link to="/assinatura">Assinatura</Link>
                            </li>
                            {
                                user.type == 'admin' &&
                                <li>
                                    <Link to="/perfil"><i className="fas fa-user-circle Centered" style={{fontSize: '1.3em', marginRight: '5px', transform: 'translateY(2px)'}}></i> Meu perfil</Link>
                                </li>
                            }
                            {
                                user.type == 'admin' ?
                                    <li>
                                        <Link to="/admin" className="site-button"><span className="Centered">Administração</span></Link>
                                    </li>
                                :
                                (
                                    token ?
                                    <li>
                                        <Link to="/perfil" className="site-button"><i className="fas fa-user-circle Centered" style={{fontSize: '1.3em', marginRight: '5px'}}></i> <span className="Centered">Meu perfil</span></Link>
                                    </li>
                                    :
                                    <li>
                                        <Link to="/cadastre-se" className="site-button">Cadastre-se grátis</Link>
                                    </li>
                                )
                            }
                        </ul>
                    </div>
                </nav>
            }
            </Container>
        </>
    );
}