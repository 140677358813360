import React, { useEffect, useState } from "react";
import {
  Link,
  Redirect
} from "react-router-dom";
import { Form, FormGroup, Input, Label, Row, Col, Container, CustomInput  } from 'reactstrap';
import ProfileLayout from '../../../../layouts/Profile';
import Wrapper from '../../../../components/Profile/Wrapper';
import CourseCard from '../../../../components/Courses/Card';
import api from '../../../../services/api';
import { useSelector } from 'react-redux';
import jwtDecode from 'jwt-decode';

export default function View() {

  const token = useSelector(state => state.auth.token);

  const [courses, setCourses] = useState([]);

  useEffect(() => {
    api.get(`users/${jwtDecode(token).sub}/courses`).then(res => {
      setCourses(res.data.courses);
    })
  }, []);

  return (
    <ProfileLayout>
        <Wrapper 
          title="Meus cursos"
          extra={
            <Link to="/cursos-online" className="site-button site-button-sm">Adquirir novos cursos</Link>
          }
        >
            <Row>
              {
                courses.map(course => (
                  <Col lg="4">
                    <CourseCard 
                      color="#14763a" 
                      image={api.defaults.baseURL.replace('.com.br/api', '.com.br/') + course.thumbPath}
                      id={course.id}
                    />
                  </Col>
                ))
              }
            </Row>
        </Wrapper>
    </ProfileLayout>
  );
}