import React, { useEffect, useState } from "react";

import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';


export default function ConfirmationDialog(props) {

  useEffect(() => {
  }, []);

  return (
    <Modal isOpen={props.isOpen} toggle={props.toggle}>
        <ModalHeader toggle={props.toggle}>Confirmar</ModalHeader>
        <ModalBody>
            {props.children}
        </ModalBody>
        <ModalFooter>
            <button className="site-button site-button-sm site-button-danger" onClick={props.callback}>Confirmar</button>{' '}
            <button className="site-button site-button-sm site-button-secondary" onClick={props.toggle}>Cancelar</button>
        </ModalFooter>
    </Modal>
  );
}