import React, { useEffect, useState } from "react";
import {
  Link,
  Redirect
} from "react-router-dom";

import { Form, FormGroup, Input, Label, Row, Col, Container, CustomInput  } from 'reactstrap';

import AdminLayout from '../../../../layouts/Admin';
import { useForm } from "react-hook-form";
import api from '../../../../services/api';

export default function View() {

  const { register, handleSubmit } = useForm();
  const [ toNexts, setToNexts ] = useState(false);
  const [finishing, setFinishing] = useState(false);
  
  useEffect(() => {
  }, []);
  
  function submit(data){
    api.post(`nexts`, {
      name: data.name,
      vagas: data.vagas,
      salary: data.salary.replace(',', '.'),
      date: data.date,
      link: data.link,
    }).then(res => {
      setToNexts('/admin/proximos-concursos');
    })
  }

  return (
    <Form onSubmit={handleSubmit(submit)}>
      { toNexts && <Redirect to={toNexts}/> }
      <AdminLayout
          title="Cadastrar próximo concurso"
          extra={
              <button type="submit" className="site-button site-button-sm" disabled={finishing}>{finishing ? 'Finalizando...' : 'Finalizar cadastro'}</button>
          }
      >
            <FormGroup>
              <Label for="nome">Nome do concurso</Label>
              <Input type="text" id="nome" name="name" innerRef={register({ required: true })} required/>
            </FormGroup>
            <FormGroup>
              <Label for="vagas">Quantidade de vagas</Label>
              <Input type="text" id="vagas" name="vagas" innerRef={register({ required: true })} required/>
            </FormGroup>
            <FormGroup>
              <Label for="salario">Salário máximo</Label>
              <Input type="text" id="salario" name="salary" innerRef={register({ required: true })} required/>
            </FormGroup>
            <FormGroup>
              <Label for="data">Data do concurso</Label>
              <Input type="date" id="data" name="date" innerRef={register({ required: true })} required/>
            </FormGroup>
            <FormGroup>
              <Label for="link">Link pro edital</Label>
              <Input type="text" id="link" name="link" innerRef={register({ required: true })} required/>
            </FormGroup>
      </AdminLayout>
    </Form>
  );
}