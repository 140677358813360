import React, { useEffect, useState } from "react";
import { Form, FormGroup, Label, Row, Col, Container, CustomInput  } from 'reactstrap';
import 'react-widgets/dist/css/react-widgets.css';
import { Multiselect } from 'react-widgets'
import api from '../../../services/api';

export default function Question(props) {
	const setQuestions = props.filterFunction;
	const [disciplines, setDisciplines] = useState([]);
	const [juries, setJuries] = useState([]);
	const [institutes, setTnstitutes] = useState([]);
	const [jobs, setJobs] = useState([]);
	const [formations, setFormations] = useState([]);
	const [tags, setTags] = useState([]);
	const [selectedDisciplines, setSelectedDisciplines] = useState([]);
	const [selectedJuries, setSelectedJuries] = useState([]);
	const [selectedInstitutes, setSelectedInstitutes] = useState([]);
	const [selectedJobs, setSelectedJobs] = useState([]);
	const [selectedFormations, setSelectedFormations] = useState([]);
	const [selectedModalities, setSelectedModalities] = useState([]);
	const [selectedDifficulties, setSelectedDifficulties] = useState([]);
	const [selectedYears, setSelectedYears] = useState([]);
	const [selectedLevels, setSelectedLevels] = useState([]);
	const [selectedTags, setSelectedTags] = useState([]);
	const [profComments, setProfComments] = useState(false);
	const [comments, setComments] = useState(false);
	const [myComments, setMyComments] = useState(false);
	const [videos, setVideos] = useState(false);

	const [nextPage, setNextPage] = useState(props.nextPage);

	const fetchData = (url, setData) => {
		api.get(url).then(res => {
			setData(res.data[url]);
		});
	};

	useEffect(() => {
		[
			['disciplines', setDisciplines],
			['juries', setJuries],
			['institutes', setTnstitutes],
			['jobs', setJobs],
			['formations', setFormations],
			['tags', setTags]
		].map(fetchArray => {fetchData(fetchArray[0], fetchArray[1])});
	}, []);

	const handleButtonClick = () => {
		api.post('questions/filter', {
			selectedDisciplines,
			selectedJuries,
			selectedInstitutes,
			selectedJobs,
			selectedFormations,
			selectedModalities,
			selectedDifficulties,
			selectedYears,
			selectedLevels,
			selectedTags,
			profComments,
			comments,
			myComments,
			videos
		}).then(res => {
			setQuestions(res.data.questions.data);
			setNextPage(res.data.next_page_url);
		});
	};

	const [loadingContent, setLoadingContent] = useState(false);

	window.onscroll = function(ev) {
		if (((window.innerHeight + window.scrollY) >= document.body.offsetHeight ) && !loadingContent) {
			setLoadingContent(true);
			props.setLoadingContent(true);
			api.post(`questions/filter?page=${nextPage}`, {
				selectedDisciplines,
				selectedJuries,
				selectedInstitutes,
				selectedJobs,
				selectedFormations,
				selectedModalities,
				selectedDifficulties,
				selectedYears,
				selectedLevels,
				selectedTags,
				profComments,
				comments,
				myComments,
				videos
			}).then(res => {
				setQuestions([...props.questions, ...res.data.questions.data]);
				setNextPage(res.data.questions.current_page + 1);
				setLoadingContent(false);
				props.setLoadingContent(false);
			});
		}
	};

	return (
		<div style={{backgroundColor: 'white', borderTop: '1px solid #CED4DA', borderBottom: '1px solid #CED4DA'}} className="py-5 mb-5">
			<Container>
				<Form>
					<Row>
						<Col lg="3" className="mb-2">
							<FormGroup>
								<Label>Disciplinas</Label>
								<Multiselect defaultValue={selectedDisciplines} onChange={value => {setSelectedDisciplines(value)}} valueField='id' textField='name' data={disciplines} />
							</FormGroup>
						</Col>
						<Col lg="3" className="mb-2">
							<FormGroup>
								<Label>Banca</Label>
								<Multiselect defaultValue={selectedJuries} onChange={value => {setSelectedJuries(value)}} valueField='id' textField='name' data={juries} />
							</FormGroup>
						</Col>
						<Col lg="3" className="mb-2">
							<FormGroup>
								<Label>Instituição</Label>
								<Multiselect defaultValue={selectedInstitutes} onChange={value => {setSelectedInstitutes(value)}} valueField='id' textField='name' data={institutes} />
							</FormGroup>
						</Col>
						<Col lg="3" className="mb-2">
							<FormGroup>
								<Label>Cargo</Label>
								<Multiselect defaultValue={selectedJobs} onChange={value => {setSelectedJobs(value)}} valueField='id' textField='name' data={jobs} />
							</FormGroup>
						</Col>
						<Col lg="3" className="mb-2">
							<FormGroup>
								<Label>Área de formação</Label>
								<Multiselect defaultValue={selectedFormations} onChange={value => {setSelectedFormations(value)}} valueField='id' textField='name' data={formations} />
							</FormGroup>
						</Col>
						<Col lg="3" className="mb-2">
							<FormGroup>
								<Label>Modalidade</Label>
								<Multiselect defaultValue={selectedModalities} onChange={value => {setSelectedModalities(value)}} valueField='id' textField='name' data={[{id: 1, name: 'Multipla escolha'}, {id: 2, name: 'Certo ou errado'}, {id: 3, name: 'Aberto'}]} />
							</FormGroup>
						</Col>
						<Col lg="2" className="mb-2">
							<FormGroup>
								<Label>Dificuldade</Label>
								<Multiselect defaultValue={selectedDifficulties} onChange={value => {setSelectedDifficulties(value)}} valueField='id' textField='name' data={[{id: 1, name: 'Fácil'}, {id: 2, name: 'Médio'}, {id: 3, name: 'Difícil'}]} />
							</FormGroup>
						</Col>
						<Col lg="2" className="mb-2">
							<FormGroup>
								<Label>Ano</Label>
								<Multiselect defaultValue={selectedYears} onChange={value => {setSelectedYears(value)}} valueField='id' textField='name' data={[
									{ id: 1, name: '1985'},
									{ id: 2, name: '1986'},
									{ id: 3, name: '1987'},
									{ id: 4, name: '1988'},
									{ id: 5, name: '1989'},
									{ id: 6, name: '1990'},
									{ id: 7, name: '1991'},
									{ id: 8, name: '1992'},
									{ id: 9, name: '1993'},
									{ id: 10, name: '1994'},
									{ id: 11, name: '1995'},
									{ id: 12, name: '1996'},
									{ id: 13, name: '1997'},
									{ id: 14, name: '1998'},
									{ id: 15, name: '1999'},
									{ id: 16, name: '2000'},
									{ id: 17, name: '2001'},
									{ id: 18, name: '2002'},
									{ id: 19, name: '2003'},
									{ id: 20, name: '2004'},
									{ id: 21, name: '2005'},
									{ id: 22, name: '2006'},
									{ id: 23, name: '2007'},
									{ id: 24, name: '2008'},
									{ id: 25, name: '2009'},
									{ id: 26, name: '2010'},
									{ id: 27, name: '2011'},
									{ id: 28, name: '2012'},
									{ id: 29, name: '2013'},
									{ id: 30, name: '2014'},
									{ id: 31, name: '2015'},
									{ id: 32, name: '2016'},
									{ id: 33, name: '2017'},
									{ id: 34, name: '2018'},
									{ id: 35, name: '2019'},
									{ id: 36, name: '2020'},
								]} />
							</FormGroup>
						</Col>
						<Col lg="2" className="mb-2">
							<FormGroup>
								<Label>Nível</Label>
								<Multiselect defaultValue={selectedLevels} onChange={value => {setSelectedLevels(value)}} valueField='id' textField='name' data={[{id: 1, name: 'Fundamental'}, {id: 2, name: 'Médio'}, {id: 3, name:'Superior'}]} />
							</FormGroup>
						</Col>
						<Col xs="12" className="mb-2">
							<FormGroup>
								<Label>Assuntos</Label>
								<Multiselect defaultValue={selectedTags} onChange={value => {setSelectedTags(value)}} valueField='id' textField='name' data={tags} />
							</FormGroup>
						</Col>
					</Row>
					<p className="mb-1">Questões com</p>
					<div className="d-flex justify-content-between">
						<div className="d-flex align-items-center">
							<CustomInput checked={profComments} onChange={e => {setProfComments(e.target.checked)}} type="checkbox" id="profComments" label="Comentários do professor" className="mr-3"/>
							<CustomInput checked={comments} onChange={e => {setComments(e.target.checked)}} type="checkbox" id="comments" label="Comentários" className="mr-3"/>
							<CustomInput checked={myComments} onChange={e => {setMyComments(e.target.checked)}} type="checkbox" id="myComments" label="Meus comentários" className="mr-3"/>
							<CustomInput checked={videos} onChange={e => {setVideos(e.target.checked)}} type="checkbox" id="videos" label="Vídeos do professor"/>
						</div>
						
						<button type="button" onClick={handleButtonClick} className="site-button">FILTRAR</button>
					</div>
				</Form>
			</Container>
		</div>
	);
}