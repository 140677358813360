import React, { useEffect, useState, useScript } from "react";
import { Form, FormGroup, Input, Label, CustomInput, Collapse } from 'reactstrap';
import { useSelector } from 'react-redux';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import jwtDecode from 'jwt-decode';
import Comment from '../Comment';
import Annotation from '../Annotation';
import PremiumWarning from '../../../components/PremiumWarning';
import api from '../../../services/api';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip } from 'recharts';
import CorrectSVG from '../../../assets/img/question/correct.svg';
import WrongSVG from '../../../assets/img/question/wrong.svg';
import { MathComponent } from 'mathjax-react'

export default function Question(props) {
	const token = useSelector(state => state.auth.token);
	const [user, setUser] = useState({});
	const [adding, setAdding] = useState(false);
	const [removing, setRemoving] = useState(false);
	const [tab, setTab] = useState(false);
	const [problem, setProblem] = useState('');
	const [buttonText, setButtonText] = useState('Enviar');

	const [comments, setComments] = useState([]);
	const [notebooks, setNotebooks] = useState([]);
	const [annotations, setAnnotations] = useState([]);

	useEffect(() => {
		console.log(props.question.wording);
		if(token) setUser(jwtDecode(token).user);
	}, []);

	function showComments(){
		setTab('comments')
		if(comments.length == 0)
			api.get(`questions/${props.question.id}/comments`).then(res => {
				setComments(res.data.comments);
			});
	}

	function showNotebooks(){
		setTab('notebooks');
		if(notebooks.length == 0)
			api.get(`notebooks`).then(res => {
				setNotebooks(res.data.notes);
			});
	}

	function showAnnotations(){
		setTab('annotations')
		if(annotations.length == 0)
			api.get(`questions/${props.question.id}/annotations`).then(res => {
				setAnnotations(res.data.annotations);
			});
	}

	const handleSendProblem = () => {
		if(problem !== '') {
			setButtonText('Enviando...');
			api.post('problems', { question_id: props.question.id, reported_by: user.id, description: problem }).then(res => {
				setButtonText('Enviado!');
			}).catch(err => {
				setButtonText('Ocorreu um erro!');
			});
			setTimeout(() => {
				setButtonText('Enviar');
			}, 2500);
			setProblem('');
		}
	};

	const [newComment, setNewComment] = useState(false);
	const [commenting, setCommenting] = useState(false);
	function comment(){
		if(newComment){
			setCommenting(true);
			api.post(`questions/${props.question.id}/comments`, {
				'content': newComment,
				'question_id': props.question.id,
			}).then(res => {
				setComments([...comments, res.data.comment]);
				setCommenting(false);
				setNewComment("");
			});
		}
	}

	function destroy(id){
		api.delete(`comments/${id}`).then(res => {
			setComments(comments.filter(comment => comment.id != id));
		});
	}

	const [noteId, setNoteId] = useState("");

	function addToNote(){
		setAdding(true);
		api.post(`notes/questions`, {
			'note_id': noteId,
			'question_id': props.question.id,
		}).then(res => {
			setNoteId("");
			setAdding(false);
		});
	}

	const [deleteNoteId, setDeleteNoteId] = useState("");

	function removeFromNote(){
		setRemoving(true);
		api.delete(`notes/${deleteNoteId}/questions/${props.question.id}`).then(res => {
			setRemoving(false);
			setDeleteNoteId("");
		});
	}

	const [annotationContent, setAnnotationContent] = useState("");

	function addAnnotation(){
		api.post(`questions/annotations`, {
			'question_id': props.question.id,
			'content': annotationContent,
		}).then(res => {
			setAnnotations([...annotations, res.data.annotation]);
			setAnnotationContent("");
		})
	}

	function removeAnnotation(id){
		setAnnotations(annotations.filter(annotation => annotation.id != id));
		api.delete(`annotations/${id}`);
	}

	const [selectedOption, selectOption] = useState("");
	const [correct, setCorrect] = useState(false);
	const [wrong, setWrong] = useState(false);
	const [verifying, setVerifying] = useState(false);

	const [totalCorrect, setTotalCorrect] = useState(0);
	const [totalWrong, setTotalWrong] = useState(0);
	const [totalPerLetter, setTotalPerLetter] = useState([]);
	const [dataAlternatives, setDataAlternatives] = useState([]);

	function submit(e){
		e.preventDefault();
		setVerifying(true);
		api.post(`responses`, {
			'question_id': props.question.id,
			'alternative_id': selectedOption,
		}).then(res => {
			const { correct, totalCorrect, totalWrong, perLetter } = res.data;

			if(correct){
				setWrong(false);
				setCorrect(true);
			} else {
				setWrong(true);
			}

			setTotalWrong(totalWrong);
			setTotalCorrect(totalCorrect);

			setVerifying(false);

			var datas = [];
			for (let [key, value] of Object.entries(perLetter)) {
				datas.push({
				  name: key,
				  Total: value.length,
				});
			}
			setDataAlternatives(datas);
		})
	}

	const [isOpen, setIsOpen] = useState(false);

	return (
		<div className="question">
			<div className="q-header">
				<div className="subject">
					<strong>{props.question.discipline_id}</strong> {props.question.discipline_name}
				</div>
				<div className="infos">
					<div><strong>Ano:</strong> {props.question.year}</div>
					<div><strong>Banca:</strong> {props.question.jury_name}</div>
					<div><strong>Instituição:</strong> {props.question.institute_name}</div>
				</div>
			</div>
			{
				props.question.text &&
				<div className="q-content mb-5">
					<button onClick={() => setIsOpen(!isOpen)} className="text-collapse-button">Texto associado { isOpen ? <i class="far fa-minus-square"></i> : <i class="far fa-plus-square"></i>}</button>
					<Collapse isOpen={isOpen}>
						<p dangerouslySetInnerHTML={{__html: props.question.text}}></p>
					</Collapse>
				</div>
			}
			<div className="q-content mb-5">
				<p dangerouslySetInnerHTML={{__html: props.question.wording}}></p>
			</div>
			<div className="q-options">
				<Form onSubmit={submit}>
					{ props.question.alternatives.map(option =>
						<Option option={option} question={props.question} selectedOption={selectedOption} selectOption={selectOption}/>
					) }
					{
						!correct &&
						<div className="mt-4">
							<button type="submit" className="site-button site-button-sm" disabled={verifying}>{verifying ? 'Verificando...' : 'Responder'}</button>
						</div>
					}
				</Form>

				{
					(correct || wrong) &&
					<div className="mt-5 d-lg-flex align-items-center text-center">
						<div className="text-center mr-lg-4">
							{
								correct ?
								<>
									<img src={CorrectSVG} height="200px"/>
									<h1 style={{fontWeight: 'bold', marginTop: '10px'}}>Você acertou!</h1>
								</>
								:
								<>
									<img src={WrongSVG} height="200px"/>
									<h1 style={{fontWeight: 'bold', marginTop: '10px'}}>Tente outra vez!</h1>
								</>
							}
						</div>
						<div>
							<h5>Veja como as outras pessoas se saíram nessa questão</h5>
							<div className="d-lg-flex text-center align-items-center mt-3">
								<div className="d-flex align-items-center">
									<div className="stat">
										<h1 style={{color: 'rgb(0, 196, 159)'}}>{totalCorrect}</h1>
										<p>Respostas certas</p>
									</div>
									<div className="stat">
										<h1 style={{color: 'red'}}>{totalWrong}</h1>
										<p>Respostas erradas</p>
									</div>
								</div>
								<BarChart
									width={290}
									height={170}
									data={dataAlternatives}
									margin={{
										top: 5, right: 30, left: 0, bottom: 5,
									}}
								>
									<XAxis dataKey="name" />
									<YAxis />
									<Tooltip />
									<Bar dataKey="Total" fill="#2087F5" />
								</BarChart>
							</div>
						</div>
					</div>
				}
			</div>
			<div className="q-footer">
				<div>
					<button className={tab == 'professorComment' ? 'button active' : 'button'} onClick={() => setTab('professorComment')}><i className="fas fa-graduation-cap"></i> Comentários do professor</button>
					<button className={tab == 'videoRes' ? 'button active' : 'button'} onClick={() => setTab('videoRes')}><i className="fas fa-video"></i> Resolução em vídeo</button>
					<button className={tab == 'comments' ? 'button active' : 'button'} onClick={() => showComments()}><i className="fas fa-comments"></i> Comentários</button>
					<button className={tab == 'notebooks' ? 'button active' : 'button'} onClick={() => showNotebooks()}><i className="fas fa-book-open"></i> Cadernos</button>
					<button className={tab == 'annotations' ? 'button active' : 'button'} onClick={() => showAnnotations()}><i className="fas fa-pencil-alt"></i> Anotações</button>
					<button className={tab == 'notify' ? 'button active' : 'button'} onClick={() => setTab('notify')}><i className="fas fa-exclamation-triangle"></i> Notificar erro</button>
				</div>
				{
					tab &&
					<>
						{
							{
								'professorComment': 
								<div className="tab professor-comment">
									<div className="close" onClick={() => setTab('')}><i className="fas fa-times"></i></div>
									{ props.premium ?
										(
											props.question.comment ?
												<Comment professor content={props.question.comment} date={props.question.updated_at} />
											:
												<h1>Essa questão ainda não tem nenhum comentário do professor.</h1>
										)
									:
										<PremiumWarning />
									}
								</div>,
								'videoRes':
								<div className="tab videoRes">
									<div className="close" onClick={() => setTab('')}><i className="fas fa-times"></i></div>
									{ props.premium ?
										(
											props.question.videoLink ?
											<div className="videoWrapper">
												<iframe width="560" height="315" src={props.question.videoLink} frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
											</div>
											:
												<h1>Essa questão ainda não tem resolução em vídeo.</h1>
										)
									:
										<PremiumWarning />
									}
								</div>,
								'comments':
								<div className="tab comments">
									<div className="close" onClick={() => setTab('')}><i className="fas fa-times"></i></div>
									{
										comments.map(comment => (
											<Comment comment={comment} destroy={destroy}/>
										))
									}
									<p>Deixe seu comentário</p>
									<CKEditor
										className="d-inline"
										editor={ ClassicEditor }
										data={newComment}
										onInit={ editor => {
											console.log('Editor is ready to use!', editor);
										} }
										onChange={ ( event, editor ) => {
											const data = editor.getData();
											setNewComment(data);
										} }
										onBlur={ ( event, editor ) => {
											console.log('Blur.', editor);
										} }
										onFocus={ ( event, editor ) => {
											console.log('Focus.', editor);
										} }
										config={{
											ckfinder: {uploadUrl: "https://api.persistir.codenative.com.br/api/ckeditor/upload"},
											toolbar: ['bold', 'italic', 'underline', 'strikethrough', 'fontColor', 'fontBackgroundColor'],
										}}
									/>
									<div className="mt-2">
										<button type="button" className="site-button site-button-sm" onClick={comment} disabled={commenting}>{commenting ? 'Comentando...' : 'Comentar'}</button>
									</div>
								</div>,
								'notebooks':
								<div className="tab notebooks">
									<div className="close" onClick={() => setTab('')}><i className="fas fa-times"></i></div>
									<FormGroup>
										<Label for="notebooks">Adicionar ao caderno</Label>
										<Input type="select" id="notebooks" value={noteId} onChange={(e) => setNoteId(e.target.value)}>
											<option value="" disabled>Selecione um caderno</option>
											{
												notebooks.map(notebook => (
													<option value={notebook.id}>{notebook.name}</option>
												))
											}
										</Input>
									</FormGroup>
									<FormGroup className="text-right">
										<button onClick={addToNote} className="site-button site-button-sm" disabled={adding}>{adding ? 'Adicionando...' : 'Adicionar'}</button>
									</FormGroup>
									<FormGroup>
										<Label for="notebooks">Remover de um caderno</Label>
										<Input type="select" id="notebooks" value={deleteNoteId} onChange={(e) => setDeleteNoteId(e.target.value)}>
											<option value="" disabled>Selecione um caderno</option>
											{
												notebooks.map(notebook => (
													<option value={notebook.id}>{notebook.name}</option>
												))
											}
										</Input>
									</FormGroup>
									<FormGroup className="text-right">
										<button onClick={removeFromNote} className="site-button site-button-sm" disabled={removing}>{removing ? 'Removendo...' : 'Remover'}</button>
									</FormGroup>
								</div>,
								'annotations':
								<div className="tab annotations">
									<div className="close" onClick={() => setTab('')}><i className="fas fa-times"></i></div>
									{
										annotations.map(annotation => (
											<Annotation annotation={annotation} destroy={removeAnnotation}/>
										))
									}
									<p>Fazer anotação</p>
									<CKEditor
										className="d-inline"
										editor={ ClassicEditor }
										data={annotationContent}
										onChange={ ( event, editor ) => {
											const data = editor.getData();
											setAnnotationContent(data);
										} }
										config={{
											ckfinder: {uploadUrl: "https://api.persistir.codenative.com.br/api/ckeditor/upload"},
											toolbar: ['bold', 'italic', 'underline', 'strikethrough', 'fontColor', 'fontBackgroundColor'],
										}}
									/>
									<div className="mt-2">
										<button type="button" onClick={addAnnotation} className="site-button site-button-sm">Comentar</button>
									</div>
								</div>,
								'notify':
								<div className="tab notify">
									<div className="close" onClick={() => setTab('')}><i className="fas fa-times"></i></div>
									<Form>
										<FormGroup>
											<Label>Descreva o erro</Label>
											<Input value={problem} onChange={e => {setProblem(e.target.value)}} type="textarea" />
										</FormGroup>
										<div className="text-right">
											<button onClick={handleSendProblem} type="button" className="ml-auto site-button site-button-sm">{buttonText}</button>
										</div>
									</Form>
								</div>
							}[tab]
						}
					</>
				}
			</div>
		</div>
	);
}

function Option({option, question, selectedOption, selectOption}){
	const [cutted, setCutted] = useState(false);

	return (
		<div className="option" style={{opacity: cutted ? 0.3 : 1}}>
			{
				(question.modal != "Certo ou errado") &&
				<span className="letter" style={{width: '25px'}}>{option.letter})</span>
			}
			<button className="cut-button" type="button" onClick={() => setCutted(!cutted)}>
				<i class="fas fa-cut"></i>
			</button>
			<CustomInput 
				type="radio" 
				id={option.id} 
				value={option.id}
				name="alternative" 
				checked={selectedOption == option.id}
				onChange={(e) => selectOption(e.target.value)}
				label={
					<span dangerouslySetInnerHTML={{__html: option.content}}></span>
				} 
			/>
		</div>
	)
}