import React, { useEffect, useState } from "react";
import {
  Link,
  Redirect,
} from "react-router-dom";

import { Form, FormGroup, Input, Label, Row, Col, Container, CustomInput  } from 'reactstrap';

import Layout from '../../../layouts/Site';

import Header from '../../../components/Header';
import { NextCard } from '../../../components/Cards';
import CourseCard from '../../../components/Courses/Card';

import api from '../../../services/api';

import Book from '../../../assets/img/header/book.svg';
import QuestionsImage from '../../../assets/img/presentations/questions.png';
import Logo from '../../../assets/img/logo.svg';

import CommentsSVG from '../../../assets/img/how/comments.svg';
import VideosSVG from '../../../assets/img/how/videos.svg';
import QuestionsSVG from '../../../assets/img/how/questions.svg';

export default function Home(props) {

    const [courses, setCourses] = useState([]);
    const [nexts, setNexts] = useState([]);
    const [total, setTotal] = useState(0);
    const [totalVideos, setTotalVideos] = useState(0);

    useEffect(() => {
        api.get('courses').then(res => setCourses(res.data.courses));
        api.get('nexts').then(res => setNexts(res.data.nexts));
        api.get('questions/data/total').then(res => setTotal(res.data.total));
        api.get('questions/data/total/videos').then(res => setTotalVideos(res.data.total));
    }, []);

    useEffect(() => {
        const script = document.createElement('script');
        script.src = "//code.jivosite.com/widget/bNRnuAUiip";
        script.async = true;
        document.body.appendChild(script);
        return () => {
            document.body.removeChild(script);
        }
    }, []);

    return (
        <Layout>
            <Header>
                <Container>
                    <h1>Resolvendo</h1>
                    <h1>o mundo</h1>
                    <h1>dos concursos</h1>
                    <p>Questões resolvidas para concursos públicos, militares e policiais.</p>
                    <Link to="/cadastre-se" className="site-button site-button-dark">COMECE AGORA</Link>
                    <div className="image">
                        <img src={Book}/>
                    </div>
                </Container>
            </Header>

            <Container>
                
                <section id="proximos-concursos" className="section">
                    <h1>Cursos online</h1>

                    <Row>
                        {
                            courses.map(course => (
                                <Col lg={3}>
                                    <CourseCard 
                                        color="#2087F5" 
                                        image={api.defaults.baseURL.replace('.com.br/api', '.com.br/') + course.thumbPath}
                                        name={course.name} 
                                        id={course.id}
                                        page
                                    />
                                </Col>
                            ))
                        }
                    </Row>
                </section>
                
                <section id="proximos-concursos" className="section">
                    <h1>Próximos concursos</h1>

                    <Row>
                        {
                            nexts.map(next => (
                                <Col lg={3}>
                                    <a href={next.link} target="_blank">
                                        <NextCard 
                                            edital={next.name}
                                            vagas={next.vagas}
                                            salario={next.salary}
                                            inscricao={next.date}
                                        />
                                    </a>
                                </Col>
                            ))
                        }
                    </Row>
                </section>
            </Container>

            <section id="como-estudar" className="section text-center">
                    <h1>Como estudar com a gente</h1>

                    <div className="howbar">
                        <Container className="content">
                            <Row>
                                <Col lg={4} className="text-center item">
                                    <img src={QuestionsSVG} height="150px"/>
                                    <h1>Resolva questões</h1>
                                    <p>Quanto mais questões você resolver, mais preparado você vai estar.</p>
                                </Col>
                                <Col lg={4} className="text-center item">
                                    <img src={VideosSVG} height="150px"/>
                                    <h1>Resoluções em vídeo</h1>
                                    <p>Ficou com dúvida na resolução? Nossos professores explicam para você.</p>
                                </Col>
                                <Col lg={4} className="text-center item">
                                    <img src={CommentsSVG} height="150px"/>
                                    <h1>Comentários do professor</h1>
                                    <p>Consiga dicas para resolver as questões através dos comentários dos professores.</p>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                    <div className="py-3 features">
                        <Container>
                            <Row>
                                <Col lg="3" className="feature">
                                    <div className="icon">
                                        <i class="far fa-check-circle"></i>
                                    </div>
                                    <div className="infos">
                                        <h1>Plataforma de questões</h1>
                                        <p>Lorem ipsum dolor sit a met et cum espirituo tum. Dominum Vobiscum.</p>
                                    </div>
                                </Col>
                                <Col lg="3" className="feature">
                                    <div className="icon">
                                        <i class="fas fa-play"></i>
                                    </div>
                                    <div className="infos">
                                        <h1>Cursos online</h1>
                                        <p>Lorem ipsum dolor sit a met et cum espirituo tum. Dominum Vobiscum.</p>
                                    </div>
                                </Col>
                                <Col lg="3" className="feature">
                                    <div className="icon">
                                        <i class="fas fa-book"></i>
                                    </div>
                                    <div className="infos">
                                        <h1>Cadernos</h1>
                                        <p>Lorem ipsum dolor sit a met et cum espirituo tum. Dominum Vobiscum.</p>
                                    </div>
                                </Col>
                                <Col lg="3" className="feature">
                                    <div className="icon">
                                        <i class="fas fa-pencil-ruler"></i>
                                    </div>
                                    <div className="infos">
                                        <h1>Anotações</h1>
                                        <p>Lorem ipsum dolor sit a met et cum espirituo tum. Dominum Vobiscum.</p>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
            </section>
                
            <Container>
                <section id="proximos-concursos" className="section">
                    <h1>Plataforma de questões</h1>

                    <Row>
                        <Col lg={6} style={{boxShadow: '6px 10px 42px -15px rgba(0,0,0,0.19)'}} className="rounded p-0">
                            <img src={QuestionsImage} className="img-fluid"/>
                        </Col>
                        <Col lg={6}>
                            <Row>
                                <Col lg={6}>
                                    <h1 className="bigTitle">{total}</h1>
                                    <span className="textBlue">questões</span>
                                    <p>Nullam pellentesque dignissim elit, sollicitudin condimentum lacus aliquet non.</p>
                                </Col>
                                <Col lg={6}>
                                    <h1 className="bigTitle">{totalVideos}</h1>
                                    <span className="textBlue">resoluções em vídeo</span>
                                    <p>Nullam pellentesque dignissim elit, sollicitudin condimentum lacus aliquet non.</p>
                                </Col>
                                <Col>
                                    <Link to="/cadastre-se" className="site-button">COMECE AGORA</Link>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </section>

                <section id="quem-somos" className="section">
                    <p class="m-0">Quem somos</p>
                    <h1 className="big-title">
                        Um ensino de alta qualidade, especialmente para aprovação<br/> em concursos e para o mercado de trabalho.
                    </h1>

                    <Row className="my-5 align-items-center">
                        <Col lg={6}>
                            <img src={Logo} style={{height: '80px'}} className="mt-3 mb-4"/>
                            <p>
                                O Persistir Concursos iniciou suas atividades como preparatório para as carreiras policiais e pré-militar. A partir daí, passamos a estender nossa experiência para concursos públicos civis, vestibulares e Escolas Técnicas. O objetivo principal é de se tornar referência em preparação para concursos, capacitação profissional e educação no Brasil. 
                            </p>
                            <p>
                                Nosso principal idealizador é Professor Fabiano Costa, com mais de 15 anos de experiência em sala de aula, tornou-se um especialista em concursos públicos sendo aprovado em diversos concursos públicos, entre eles: ESA, EEAR, EFOMM, PRF, PF e atualmente exerce o cargo de Perito Criminal em Minas Gerais.
                            </p>

                            <Link to="/cadastre-se" className="site-button">COMECE AGORA</Link>
                        </Col>
                        <Col lg={6}>
                            <Row>
                                <Col lg={6}>
                                    <h1 className="bigTitle">5</h1>
                                    <span className="textBlue">anos</span>
                                    <p>Nullam pellentesque dignissim elit, sollicitudin condimentum lacus aliquet non.</p>
                                </Col>
                                <Col lg={6}>
                                    <h1 className="bigTitle">10</h1>
                                    <span className="textBlue">preparatórios</span>
                                    <p>Nullam pellentesque dignissim elit, sollicitudin condimentum lacus aliquet non.</p>
                                </Col>
                                <Col lg={6}>
                                    <h1 className="bigTitle">+500</h1>
                                    <span className="textBlue">alunos</span>
                                    <p>Nullam pellentesque dignissim elit, sollicitudin condimentum lacus aliquet non.</p>
                                </Col>
                                <Col lg={6}>
                                    <h1 className="bigTitle">+100</h1>
                                    <span className="textBlue">aprovados</span>
                                    <p>Nullam pellentesque dignissim elit, sollicitudin condimentum lacus aliquet non.</p>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </section>

                <section id="contato" className="section">
                    <div className="text-center">
                        <p className="mb-0">Comece seu futuro agora</p>
                        <h1 className="big-title"><strong>Nos mande uma mensagem</strong></h1>
                    </div>
                    <Row className="mt-5">
                        <Col lg={6}>
                            <h1>Via e-mail</h1>
                            <Form>
                                <FormGroup>
                                    <Label for="name">Nome</Label>
                                    <Input type="text" id="name"/>
                                </FormGroup>
                                <FormGroup>
                                    <Label for="tel">Telefone</Label>
                                    <Input type="text" id="tel"/>
                                </FormGroup>
                                <FormGroup>
                                    <Label for="email">E-mail</Label>
                                    <Input type="text" id="email"/>
                                </FormGroup>
                                <FormGroup>
                                    <Label for="mensagem">Mensagem</Label>
                                    <Input type="textarea" id="mensagem"/>
                                </FormGroup>
                                <div class="text-center">
                                    <button type="submit" className="site-button">ENVIAR</button>
                                </div>
                            </Form>
                        </Col>
                        <Col lg={6}>
                            <div className="mb-5">
                                <h1>Via telefone</h1>
                                <h1><i class="fab fa-whatsapp"></i> (32) 9 9185-1977</h1>
                                <a href="tel:5532991851977" target="_blank" className="site-button site-button-dark">LIGAR AGORA</a>{' '} 
                                <a href="https://api.whatsapp.com/send?phone=5532991851977" target="_blank" className="site-button site-button-success">CHAMAR NO WHATSAPP</a>
                            </div>
                            <div>
                                <h1>Ou venha nos visitar</h1>
                                <p><i class="fas fa-map-marker-alt"></i> Avenida Astolfo Dutra, 792/101, Centro, Cataguases</p>
                            </div>
                        </Col>
                    </Row>
                </section>
            </Container>
        </Layout>
    );
}