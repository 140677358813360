import React, { useEffect, useState } from "react";
import {
  Link,
  Redirect
} from "react-router-dom";

import { Form, FormGroup, Input, Label, Row, Col, Container, CustomInput, Table } from 'reactstrap';

import ConfirmationDialog from '../../../components/ConfirmationDialog';

import AdminLayout from '../../../layouts/Admin';

import api from '../../../services/api';

export default function View(props) {

    const [courses, setCourses] = useState([]);

    useEffect(() => {
        api.get(`courses`).then(res => {
            setCourses(res.data.courses);
        })
    }, []);

    const [confirmationDialog, setConfirmationDialog] = useState(false);
    const [dialogCache, setDialogCache] = useState();

    function toggleDialog(){
        setConfirmationDialog(!confirmationDialog);
    }

    function callbackDialog(){
        api.delete(`courses/${dialogCache}`);
        setCourses(courses.filter(course => course.id != dialogCache));
        toggleDialog();
    }

    return (
        <AdminLayout
            title="Cursos"
            extra={
                <Link to="cursos/cadastrar" className="site-button site-button-sm">Cadastrar novo curso</Link>
            }
        >
            <Table responsive>
                <thead>
                    <tr>
                        <th>Nome</th>
                        <th style={{width: '130px'}} className="text-center">Qtd. Alunos</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {
                        courses.map(course => (
                            <tr>
                                <td>{course.name}</td>
                                <td className="text-center">{course.qtd}</td>
                                <td className="text-right">
                                    <Link to={`cursos/${course.id}/alunos`} className="site-button site-button-sm site-button-info">Alunos</Link>{' '}
                                    <Link to={`cursos/${course.id}`} className="site-button site-button-sm">Editar</Link>{' '}
                                    <Link to={`cursos/${course.id}/aulas`} className="site-button site-button-sm site-button-dark">Aulas</Link>{' '}
                                    <button className="site-button site-button-danger site-button-sm" onClick={() => {toggleDialog(); setDialogCache(course.id)}}>Deletar</button>
                                </td>
                            </tr>
                        ))
                    }
                </tbody>
            </Table>

            <ConfirmationDialog
                isOpen={confirmationDialog}
                toggle={toggleDialog}
                callback={callbackDialog}
            >
                Você tem certeza que deseja deletar esse curso?
            </ConfirmationDialog>
        </AdminLayout>
    );
}