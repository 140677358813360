import React, { useEffect, useState } from "react";
import {
  Link,
  Redirect
} from "react-router-dom";

import { Form, FormGroup, Input, Label, Row, Col, Container, CustomInput  } from 'reactstrap';


export default function Card(props) {

  useEffect(() => {
  }, []);

  function replaceAll(string, search, replace) {
    return string.split(search).join(replace);
  }

  return (
    props.id ?
      (
        props.page ?
        <Link to={`/cursos/${props.id}/${replaceAll(props.name.toLowerCase(), ' ', '-' )}`} className="courseThumb" style={{backgroundColor: props.color}}>
            <img src={props.image} style={{width: '100%'}} alt={props.name}/>
        </Link>
        :
        <Link to={`/perfil/cursos/${props.id}`} className="courseThumb" style={{backgroundColor: props.color}}>
            <img src={props.image} style={{width: '100%'}} alt={props.name}/>
        </Link>
      )
    :
      <div className="courseThumb" style={{backgroundColor: props.color}}>
          <img src={props.image} style={{width: '100%'}} alt={props.name}/>
      </div>
  );
}