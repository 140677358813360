import React, { useEffect, useState } from "react";
import {
  Link,
  Redirect
} from "react-router-dom";

import { Form, FormGroup, Input, Label, Row, Col, Container, CustomInput  } from 'reactstrap';

import ProfileLayout from '../../../../layouts/Profile';

import Wrapper from '../../../../components/Profile/Wrapper';

import PremiumWarning from '../../../../components/PremiumWarning';
import PremiumSVG from '../../../../assets/img/premium.svg';
import api from '../../../../services/api';
import { useSelector } from 'react-redux';
import jwtDecode from 'jwt-decode';

export default function View() {

  const token = useSelector(state => state.auth.token);
  const [user, setUser] = useState({});

  const [premium, setPremium] = useState(false);

  useEffect(() => {
    api.get('auth/user').then(res => {
      setUser(res.data.user);
      console.log(res.data.user);
    })
  }, []);

  return (
    <ProfileLayout>
        <Wrapper title="Assinatura">
          {
            user.type == 'premium' ?
            <>
            <div className="text-center">
              <img src={PremiumSVG} style={{maxHeight: '250px', maxWidth: '100%'}}/>
              <div className="mb-2">
                <h1>Você é um usuário premium!</h1>
                <p>Sua assinatura é válida até o dia <strong>20/08/2020</strong></p>
              </div>

              <p className="mb-1">Precisa de ajuda?</p>
              <button className="site-button site-button-primary">FALE CONOSCO</button>
            </div>
            </>
            :
            <PremiumWarning
              customTitle={"Ative sua assinatura agora!"}
              customDesc={"Assinando o nosso site, agora você terá acesso a todos os benefícios e estará nos ajudando a manter a plataforma."}
            />
          }
        </Wrapper>
    </ProfileLayout>
  );
}