import React from "react";
import BounceLoader from "react-spinners/BounceLoader";

export default function Loader(props){
    return (
      <div className="loader" style={{
          position: 'absolute',
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
      }}>
        <BounceLoader
          size={65}
          color={"#2087F5"}
          loading={props.loading}
        />
      </div>
    )
  }