import React, { useEffect, useState } from "react";
import {
  Link,
  Redirect
} from "react-router-dom";

import { Form, FormGroup, Input, Label, Row, Col, Container, CustomInput  } from 'reactstrap';

import ProfileLayout from '../../../../layouts/Profile';

import Wrapper from '../../../..//components/Profile/Wrapper';

import api from '../../../../services/api';

import { ResponsiveContainer, PieChart, Pie, Sector, Cell, AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip } from 'recharts';

const COLORS = ['#EE2A2A', '#00C49F' ];

export default function View() {

  const [data, setData] = useState([{}]);
  const [totalWrong, setTotalWrong] = useState(0);
  const [totalCorrect, setTotalCorrect] = useState(0);
  const [dataArea, setDataArea] = useState([]);

  useEffect(() => {
    api.get('users/profile/dashboard').then(res => {
      setData([
        { name: 'Erros', value: res.data.totalWrong },
        { name: 'Acertos', value: res.data.totalCorrect },
      ]);

      setTotalWrong(res.data.totalWrong);
      setTotalCorrect(res.data.totalCorrect);
    });

    var datas = [];
    api.get('responses/total/seven').then(res => {
      for (let [key, value] of Object.entries(res.data.responses)) {
        datas.push({
          name: key,
          Total: value.length,
        });
      }

      setDataArea(datas);
    })
  }, []);

  useEffect(() => {
    console.log(dataArea);
  }, [dataArea])

  return (
    <ProfileLayout>
        <Wrapper 
          title="Meu desempenho"
          extra={
              false &&
              <Input type="select" name="type" id="type">
                <option>Tipo de concurso</option>
                <option>Militar</option>
                <option>Vestibular</option>
              </Input>
          }
        >
          <Row>
            <Col lg="4" className="mb-5 mb-lg-0 p-0">
              <div className="text-center">
                Relação total
              </div>
              <div style={{ width: '100%', height: 180 }}>
                <ResponsiveContainer>
                  <PieChart>
                    <Pie
                      data={data}
                      innerRadius={60}
                      outerRadius={80}
                      fill="#8884d8"
                      paddingAngle={5}
                      dataKey="value"
                    >
                      {
                        data.map((entry, index) => <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />)
                      }
                    </Pie>
                  </PieChart>
                </ResponsiveContainer>
                <div className="text-center">
                  Erros {totalWrong} <span style={{width: '10px', height: '10px', backgroundColor: 'red', display: 'inline-block'}}></span>{' '}
                  <span style={{width: '10px', height: '10px', backgroundColor: '#00C49F', display: 'inline-block'}}></span>{' '}{totalCorrect} Acertos
                </div>
              </div>
            </Col>
            <Col lg="8" className="p-0">
              <div className="text-center">
                Resoluções e tentativas nos últimos 7 dias
              </div>
              <div style={{ width: '100%', height: 200 }}>
                <ResponsiveContainer>
                  <AreaChart
                    width={500}
                    height={400}
                    data={dataArea}
                    margin={{
                      top: 10, right: 30, left: 0, bottom: 0,
                    }}
                  >
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    <Area type="monotone" dataKey="Total" stroke="#3793F5" fill="#50A4FD" />
                  </AreaChart>
                </ResponsiveContainer>
              </div>
            </Col>
          </Row>


        </Wrapper>
    </ProfileLayout>
  );
}