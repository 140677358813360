import React, { useEffect, useState } from "react";
import {
  Link,
  Redirect
} from "react-router-dom";

import { Form, FormGroup, Input, Label, Row, Col, Container, CustomInput  } from 'reactstrap';

import Layout from '../../../../layouts/Site';
import CourseCard from '../../../../components/Courses/Card';
import api from '../../../../services/api';

export default function CoursePage(props) {

    const [courses, setCourses] = useState([]);

    useEffect(() => {
        api.get('courses').then(res => setCourses(res.data.courses));
    }, []);

    return (
        <Layout>
            <Container>

            <section id="proximos-concursos" className="section">
                    <h1>Cursos online</h1>

                    <Row>
                        {
                            courses.map(course => (
                                <Col lg={3}>
                                    <CourseCard 
                                        color="#2087F5" 
                                        image={api.defaults.baseURL.replace('.com.br/api', '.com.br/') + course.thumbPath}
                                        name={course.name} 
                                        id={course.id}
                                        page
                                    />
                                </Col>
                            ))
                        }
                    </Row>
                </section>

            </Container>
        </Layout>
    );
}