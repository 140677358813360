import React, { useEffect, useState } from "react";
import {
  Link,
  Redirect
} from "react-router-dom";
import { Form, FormGroup, Input, Label, Row, Col, Container, FormFeedback  } from 'reactstrap';
import Layout from '../../layouts/Site';
import { Card } from '../../components/Cards';
import api from '../../services/api';
import { loginAction } from '../../store/modules/auth/actions';
import { useSelector, useDispatch } from 'react-redux';

export default function Login(props) {

    const [loading, setLoading] = useState(false);
    const [registering, setRegistering] = useState(false);
    const [wrongPass, setWrongPass] = useState(false);
    const [emailExists, setEmailExists] = useState(false);

    const [email, setEmail] = useState();
    const [password, setPassword] = useState();

    const [registerName, setRegisterName] = useState();
    const [registerLastname, setRegisterLastname] = useState();
    const [registerEmail, setRegisterEmail] = useState();
    const [registerPassword, setRegisterPassword] = useState();
    const [diffPassword, setDiffPassword] = useState();

    const dispatch = useDispatch();

    const token = useSelector(state => state.auth.token);

    const [toHome, setToHome] = useState(false);

    useEffect(() => {
    }, []);

    function login(e){
        e.preventDefault();
        setLoading(true);

        api.post('auth/login', {
            email,
            password
        }).then(res => {
            dispatch(loginAction(res.data.access_token));
            setLoading(false);
            setToHome(true);
        }).catch(err => {
            setWrongPass(true);
            setTimeout(function(){ setWrongPass(false) }, 3000);
            setLoading(false);
            setEmail("");
            setPassword("");
        })
    }

    function register(e){
        e.preventDefault();

        if(!diffPassword){
            setRegistering(true);
            api.post('/users', {
                name: registerName + ' ' + registerLastname,
                email: registerEmail,
                password: registerPassword,
            }).then(res => {
                api.post('auth/login', {
                    email: registerEmail,
                    password: registerPassword,
                }).then(res => {
                    dispatch(loginAction(res.data.access_token));
                    setRegistering(false);
                    setToHome(true);
                })
            }).catch(err => {
                if(err.response.data.message == "Email já cadastrado"){
                    setEmailExists(true);
                    setRegistering(false);
                    setTimeout(function(){ setEmailExists(false) }, 3000);
                }
            })
        }
    }

    return (
        <Layout>
            { toHome && <Redirect to="/"/> }
            <Container>
                <section id="login" className="section ml-auto mr-auto" style={{maxWidth: '900px'}}>
                    <div className="text-center">
                        <h1>Entre ou cadastre-se para ter acesso às questões.</h1>
                    </div>
                    <Row>
                        <Col lg="6">
                            <Card
                                title="Faça login"
                                contentCard
                            >
                                <Form className="text-left" onSubmit={login}>
                                    <FormGroup>
                                        <Label for="email">E-mail</Label>
                                        <Input type="email" id="email" value={email} onChange={(e) => setEmail(e.target.value)} invalid={wrongPass}/>
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="pass">Senha</Label>
                                        <Input type="password" id="senha" value={password} onChange={(e) => setPassword(e.target.value)} invalid={wrongPass}/>
                                        <FormFeedback>E-mail ou senha incorretos!</FormFeedback>
                                    </FormGroup>
                                    <FormGroup>
                                        <button type="submit" className="site-button" disabled={loading}>{ loading ? 'Verificando...' : 'Entrar' }</button>
                                    </FormGroup>
                                </Form>
                            </Card>
                        </Col>
                        <Col lg="6">
                            <Card
                                title="Ou cadastre-se"
                                contentCard
                            >
                                <Form className="text-left" onSubmit={register}>
                                    <div className="d-flex">
                                        <FormGroup className="mr-1">
                                            <Label for="">Nome</Label>
                                            <Input type="text" value={registerName} onChange={(e) => setRegisterName(e.target.value)} required/>
                                        </FormGroup>
                                        <FormGroup className="ml-1">
                                            <Label for="">Sobrenome</Label>
                                            <Input type="text" value={registerLastname} onChange={(e) => setRegisterLastname(e.target.value)} required/>
                                        </FormGroup>
                                    </div>
                                    <FormGroup>
                                        <Label for="email">E-mail</Label>
                                        <Input type="email" id="email" value={registerEmail} onChange={(e) => setRegisterEmail(e.target.value)} invalid={emailExists} required/>
                                        <FormFeedback>Este e-mail já está cadastrado em nosso site.</FormFeedback>
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="pass">Senha</Label>
                                        <Input type="password" id="senha" minLength="8" value={registerPassword} onChange={(e) => setRegisterPassword(e.target.value)} invalid={diffPassword} required/>
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="pass">Confirme sua senha</Label>
                                        <Input type="password" id="senha" minLength="8" onBlur={(e) => { e.target.value != registerPassword ? setDiffPassword(true) : setDiffPassword(false) } } invalid={diffPassword} required/>
                                        <FormFeedback>As senhas não são iguais!</FormFeedback>
                                    </FormGroup>
                                    <FormGroup>
                                        <button type="submit" className="site-button" disabled={registering}>{ registering ? 'Cadastrando...' : 'Cadastrar' }</button>
                                    </FormGroup>
                                </Form>
                            </Card>
                        </Col>
                    </Row>
                </section>
            </Container>
        </Layout>
    );
}