import React, { useEffect, useState } from "react";
import {
  Link,
  Redirect
} from "react-router-dom";

import { Badge, Table } from 'reactstrap';

import AdminLayout from '../../../layouts/Admin';
import api from '../../../services/api';

export default function View() {

    const [users, setUsers] = useState([]);
    useEffect(() => {
        api.get(`users`).then(res => {
            setUsers(res.data.users);
        });
    }, []);

    return (
        <AdminLayout
            title="Usuários"
        >
            <Table responsive>
                <thead>
                    <tr>
                        <th>Nome</th>
                        <th>Email</th>
                        <th>Celular</th>
                        <th>Tipo</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        users.map(user => (
                            <tr>
                                <td>{user.name}</td>
                                <td>{user.email}</td>
                                <td>{user.cel}</td>
                                <td>{
                                    {
                                        'premium': <Badge color="primary">Premium</Badge>,
                                        'user': <Badge color="secondary">Usuário</Badge>,
                                        'admin': <Badge color="success">Admin</Badge>
                                      }[user.type]
                                }</td>
                            </tr>
                        ))
                    }
                </tbody>
            </Table>
        </AdminLayout>
    );
}