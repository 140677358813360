import React, { useEffect, useState } from "react";
import {
  Link,
  Redirect
} from "react-router-dom";
import { Table } from 'reactstrap';
import AdminLayout from '../../../../../layouts/Admin';
import { useSelector } from 'react-redux';
import jwtDecode from 'jwt-decode';
import api from '../../../../../services/api';

export default function View(props) {

  const token = useSelector(state => state.auth.token);
  const [courses, setCourses] = useState([]);
  const [username, setUsername]  = useState("");

  useEffect(() => {
    api.get(`users/${props.match.params.id}/courses`).then(res => {
      setCourses(res.data.courses);
    });
    api.get(`users/${jwtDecode(token).sub}`).then(res => {
      setUsername(res.data.user.name);
    })
  }, []);

  return (
    <AdminLayout
        title={`${username} - Cursos`}
    >
        <Table responsive>
            <thead>
              <tr>
                <th className="text-center">#</th>
                <th>Nome</th>
                <th>Adquirido em</th>
              </tr>
            </thead>
            <tbody>
              {
                courses.map((course, index) => (
                  <tr>
                      <td className="text-center">{index + 1}</td>
                      <td>{course.name}</td>
                      <td>
                        {course.pivot.created_at}
                      </td>
                  </tr>
                ))
              }
            </tbody>
        </Table>
    </AdminLayout>
  );
}